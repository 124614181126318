import React from "react";

import styles from "./HomeCustomers.module.css";

export const HomeCustomers = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.object}>
      <div className={styles.person}>
        <svg
          width="54"
          height="58"
          viewBox="0 0 54 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="26.8887" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M53 58C53 46.9543 41.3594 38 27 38C12.6406 38 1 46.9543 1 58"
            stroke="black"
            strokeWidth="2"
          />
        </svg>
        <div className={styles.scanner}></div>
      </div>
      <div className={styles.status}></div>
    </div>
    <div className={styles.object}>
      <div className={styles.person}>
        <svg
          width="54"
          height="58"
          viewBox="0 0 54 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="26.8887" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M53 58C53 46.9543 41.3594 38 27 38C12.6406 38 1 46.9543 1 58"
            stroke="black"
            strokeWidth="2"
          />
        </svg>
        <div className={styles.scanner}></div>
      </div>
      <div className={styles.status}></div>
    </div>
    <div className={styles.object}>
      <div className={styles.person}>
        <svg
          width="54"
          height="58"
          viewBox="0 0 54 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="26.8887" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M53 58C53 46.9543 41.3594 38 27 38C12.6406 38 1 46.9543 1 58"
            stroke="black"
            strokeWidth="2"
          />
        </svg>
        <div className={styles.scanner}></div>
      </div>
      <div className={styles.status}></div>
    </div>
  </div>
);
