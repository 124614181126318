import React from "react";

import styles from "./HomeSpend.module.css";

export const HomeSpend = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.spendRules}>
      <div className={styles.spendRule}>
        Block CVV No Match
        <div className={styles.switch}>
          <div className={styles.circle}></div>
        </div>
      </div>
      <div className={styles.spendRule}>
        Block Airlines
        <div className={styles.switch}>
          <div className={styles.circle}></div>
        </div>
      </div>
      <div className={styles.spendRule}>
        Limit Spend $2000/mo
        <div className={styles.switch}>
          <div className={styles.circle}></div>
        </div>
      </div>
      <div className={styles.spendRule}>
        Block Transactions Over $500
        <div className={styles.switch}>
          <div className={styles.circle}></div>
        </div>
      </div>
      <div className={styles.spendRule}>
        Block Grocery Stores
        <div className={styles.switch}>
          <div className={styles.circle}></div>
        </div>
      </div>
    </div>
    <div className={styles.overlayTop}></div>
    <div className={styles.overlayBottom}></div>
  </div>
);
