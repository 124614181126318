import React from "react";

import styles from "./HomeLedger.module.css";

export const HomeLedger = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.ledgers}>
      <div className={styles.ledger}>
        <div className={styles.name}>Cash</div>
        <div className={styles.amount}></div>
      </div>
      <div className={styles.ledger}>
        <div className={styles.name}>Funds on Hold</div>
        <div className={styles.amount}></div>
      </div>
      <div className={styles.ledger}>
        <div className={styles.name}>Refund Hold</div>
        <div className={styles.amount}></div>
      </div>
      <div className={styles.ledger}>
        <div className={styles.name}>Authorizations</div>
        <div className={styles.amount}></div>
      </div>
    </div>
    <svg
      width="362"
      height="201"
      viewBox="0 0 362 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.box}
    >
      <path
        d="M1 0V180C1 191.046 9.95431 200 21 200H341C352.046 200 361 191.046 361 180V0"
        stroke="black"
        strokeWidth="2"
        strokeDasharray="2 6"
      />
    </svg>
    <div className={styles.overlayTop}></div>
  </div>
);
